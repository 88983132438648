<template>
  <div>
    <v-bottom-navigation color="info" horizontal :background-color="color" dark>
      <v-btn to="/college/personnel_etc" large class="mr-10">
        <span class="bottom-navi">ข้อมูลบุคคลสถานศึกษา </span>
        <v-icon>mdi-account-multiple</v-icon>
      </v-btn>
      <v-btn to="/college/rate_workforce_g" large class="mr-10">
        <span class="bottom-navi">งานอัตราสถานศึกษา </span>
        <v-icon>mdi-numeric-1-box</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูล ข้าราชการ ลูกจ้างประจำ พนักงานราชการ ครูอัตราจ้างหรือลูกจ้างชั่วคราว"
        class="px-5 py-3"
      >
        <div class="text-right">
          <v-btn text color="red" @click="VideoDialogConditionB()">
            <v-icon large color="red">mdi-video</v-icon>
          </v-btn>
        </div>
        <v-card class="pa-1">
          <v-alert type="info" outlined>
            <div>
              <h3>ข้อมูลบุคคลสถานศึกษาในส่วนนี้ เกี่ยวข้องกับระบบ ID Plan ให้ปรับปรุงให้เป็นปัจจุบัน โดยระบบจะส่งข้อมูลไปยังระบบ ID Plan ทุกวันในเวลาเที่ยงคืน</h3>
             <div>
              สถานศึกษาสามารถลบบุคคลที่ไม่ได้เกี่ยวข้องกับสถานศึกษาออกจากระบบได้ให้เป็นปัจจุบัน
             </div> 
         
              
            </div>
          </v-alert>
        </v-card>
        <v-card class="pa-5">
          <div style="text-indent:2em;">
            1.
            <span class="red--text font-weight-bold">ค้นหาบุคคลจากฐานข้อมูลงานอัตรากำลังของสถานศึกษาอื่น ๆ ในระบบ</span>
            หากไม่ปรากฎให้ดำเนินการยังปุ่ม 
            <span class="font-weight-bold">
              เพิ่มบุคลากร/ค้นหาข้าราชการจากระบบหลัก
            </span>           
          </div>
          <div style="text-indent:2em;">
            2.กรณี
            <span class="red--text font-weight-bold">ข้าราชการ</span>
            ให้ดำเนินการยังปุ่ม
            <span class="font-weight-bold">
              เพิ่มบุคลากร/ค้นหาข้าราชการจากระบบหลัก
            </span>
            และไปยังเครื่องมือค้นหา โดยค้นหาจากหมายเลขบัตรประชาชน
            หากรายการซ้ำให้ดำเนินการค้นหาในระบบอัตรากำลังจากสถานศึกษา
          </div>
          <div style="text-indent:2em;">
            3.กรณี
            <span class="red--text font-weight-bold"
              >ลูกจ้างประจำ พนักงานราชการ ลูกจ้างชั่วคราว</span
            >
            ให้ดำเนินการยังปุ่ม
            <span class="font-weight-bold">
              เพิ่มบุคลากร/ค้นหาข้าราชการจากระบบหลัก
            </span>
            โดยกรอกข้อมูลเพิ่มบุคลากร สามารถทำได้ 2 วิธี คือ 1)
            กรอกเพิ่มที่ละรายบุคคล หรือ 2) นำเข้าข้อมูลเบื้องต้นผ่านไฟล์ .CSV
          </div>
        </v-card>
        <v-card class="pa-2">
          <v-row no-gutters>
            <v-col cols="12" md="8" class="pa-1">
              <v-simple-table class="elevation-1">
                <thead style="background-color: wheat;">
                  <tr>
                    <th class="text-left font-weight-bold">
                      ประเภท
                    </th>
                    <th class="text-left font-weight-bold">
                      จำนวน (คน)
                    </th>
                    <th class="text-left font-weight-bold">
                      ประเภท
                    </th>
                    <th class="text-left font-weight-bold">
                      จำนวน (คน)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-left">ผู้อำนวยการ</td>
                    <td class="text-left">
                      {{ personnel_etcsCounts.directors }}
                    </td>
                    <td class="text-left">รองผู้อำนวยการ</td>
                    <td class="text-left">
                      {{ personnel_etcsCounts.se_directors }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">ข้าราชการครู</td>
                    <td class="text-left">
                      {{ personnel_etcsCounts.teachers }}
                    </td>
                    <td class="text-left">ศึกษานิเทศก์</td>
                    <td class="text-left">
                      {{ personnel_etcsCounts.supervisions }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">สนับสนุนการสอน</td>
                    <td class="text-left">
                      {{ personnel_etcsCounts.support_38 }}
                    </td>
                    <td class="text-left">ลูกจ้างประจำ</td>
                    <td class="text-left">
                      {{ personnel_etcsCounts.permanents }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">พนักงานราชการ(สายการสอน)</td>
                    <td class="text-left">
                      {{ personnel_etcsCounts.governmentteach }}
                    </td>
                    <td class="text-left">ครูอัตราจ้าง</td>
                    <td class="text-left">
                      {{ personnel_etcsCounts.temporarys }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">พนักงานราชการ(สายสนับสนุน)</td>
                    <td class="text-left">
                      {{ personnel_etcsCounts.governmentsp }}
                    </td>
                    <td class="text-left">ลูกจ้างชั่วคราว</td>
                    <td class="text-left">
                      {{ personnel_etcsCounts.temporary_jobs }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      class="text-center font-weight-bold"
                      style="background-color: wheat;"
                    >
                      รวมทั้งสิ้น
                    </td>
                    <td
                      colspan="2"
                      class="text-center font-weight-bold"
                      style="background-color: wheat;"
                    >
                      {{ personnel_etcsCounts.sumAll }} คน
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col cols="12" md="4">
              <div>
                <v-btn
                  block
                  right
                  depressed
                  color="primary"
                  @click.native="personnel_etcAdd()"
                >
                  <v-icon>mdi-plus-circle-outline</v-icon>เพิ่มบุคลากร /
                  ค้นหาข้าราชการจากระบบหลัก
                </v-btn>
                <v-btn @click.native="personnel_etcCSV()" block color="warning">
                  <v-icon>mdi-file-excel</v-icon> นำเข้าข้อมูลเบื้องต้นผ่านไฟล์
                  .CSV</v-btn
                >
              </div>

              <div class="mt-15">
                <v-btn
                  block
                  right
                  depressed
                  color="info"
                  :href="
                    '#/college/print_report_personnel_etc/' + user.user_code
                  "
                  target="_blank"
                >
                  <v-icon>mdi-printer</v-icon>พิมพ์รายชื่อ
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <v-card>
          <v-row no-gutters>
            <v-col cols="12" md="12" class="pa-5">
              <h2 class="font-weight-bold red--text">
                <v-icon>mdi-account-search</v-icon>
                ค้นหาบุคคลจากฐานข้อมูลงานอัตรากำลังของสถานศึกษาอื่น ๆ ในระบบ
              </h2>
            </v-col>
            <v-col cols="12" md="8" class="pa-1">
              <v-text-field
                v-model="searchIDcard"
                append-icon="mdi-magnify"
                label="ค้นหาบุคลากรในระบบอัตรากำลังจากสถานศึกษาอื่น ๆ : ด้วยรหัสบัตรประชาชน"
                single-line
                hide-details
                clearable
                solo-inverted
                flat
                class="mb-2"
              />
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-btn
                block
                right
                depressed
                color="info"
                @click.native="personnel_etcSearchSYS()"
              >
                <v-icon>mdi-account-search</v-icon> ค้นหา
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="12"
              v-if="personnel_etcSYSs.personnel_etc_id_card"
            >
              <v-form ref="addpersonnel_reqform" lazy-validation="">
                <v-alert outlined type="warning" prominent>
                  <div>
                    {{ personnel_etcSYSs.college_name }} <br />
                    {{ personnel_etcSYSs.personnel_etc_id_card }}
                    {{ personnel_etcSYSs.personnel_etc_title }}
                    {{ personnel_etcSYSs.personnel_etc_fristname }}
                    {{ personnel_etcSYSs.personnel_etc_lastname }}
                  </div>

                  <div
                    v-if="
                      user.user_code !==
                        personnel_etcSYSs.personnel_etc_college_code
                    "
                  >
                    <v-btn color="primary" @click="personnel_reqAdd()"
                      >ขอเพิ่มข้อมูลบุคคล</v-btn
                    >
                  </div>
                  <div v-else>
                    <v-icon>mdi-information-outline</v-icon>
                    บุคคลดังกล่าวอยู่วิทยาลัยของท่าน
                  </div>
                </v-alert>
              </v-form>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" md="6" class="pa-2">
              <v-alert outlined color="green">
                <h3>รายการขอเพิ่มบุคคล</h3>

                <v-data-table
                  :headers="personnel_reqHearder"
                  :items="personnel_reqReqNTB"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <template v-slot:item.personel_reqIDcard="{ item }">
                    {{
                      item.personnel_etc_title +
                        item.personnel_etc_fristname +
                        " " +
                        item.personnel_etc_lastname
                    }}
                  </template>

                  <template v-slot:item.personel_reqStatus="{ item }">
                    <v-chip
                      dark
                      color="grey"
                      v-if="item.personel_reqStatus === 'req'"
                      >รอตรวจสอบ</v-chip
                    >
                  </template>

                  <template v-slot:item.personel_reqDate="{ item }">
                    {{
                      item.personel_reqDate
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </template>

                  <template v-slot:item.action="{ item }">
                    <v-btn
                      rounded
                      color="warning"
                      @click="
                        cancelReg(
                          item.personel_reqNewCollege,
                          item.personel_reqIDcard
                        )
                      "
                    >
                      <v-icon>mdi-close</v-icon> ยกเลิก</v-btn
                    >
                  </template>
                </v-data-table></v-alert
              >
            </v-col>
            <v-col cols="12" md="6" class="pa-2">
              <v-alert outlined color="warning">
                <h3>รายการขออนุมัติย้ายข้อมูลบุคคล</h3>

                <v-data-table
                  :headers="personnel_reqOHearder"
                  :items="personnel_reqReqOTB"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <template v-slot:item.personel_reqIDcard="{ item }">
                    {{
                      item.personnel_etc_title +
                        item.personnel_etc_fristname +
                        " " +
                        item.personnel_etc_lastname
                    }}
                  </template>

                  <template v-slot:item.personel_reqStatus="{ item }">
                    <v-btn
                      @click="personnel_reqApprove(item.personel_reqIDcard)"
                      color="green"
                      dark
                      v-if="item.personel_reqStatus === 'req'"
                      >อนุนัติย้ายข้อมูล</v-btn
                    >
                  </template>

                  <template v-slot:item.personel_reqDate="{ item }">
                    {{
                      item.personel_reqDate
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </template>
                </v-data-table>
              </v-alert>
            </v-col>
          </v-row>
        </v-card>
        <v-alert outlined color="red">
          <v-row no-gutters>
            <v-col cols="12" md="12" class="pa-5">
              <h2 class="font-weight-bold info--text">
                <v-icon>mdi-account-search</v-icon> ค้นหาบุคคลในฐานข้อมูลของ
                {{ user.college_name }}
              </h2>
            </v-col>
          </v-row>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
            single-line
            hide-details
            clearable
            solo-inverted
            flat
            class="mb-2"
          />

          <div class="font-weight-bold">
            <v-icon color="yellow">mdi-checkbox-blank</v-icon>
            ยังไม่ได้กำหนดประเภทบุคลากรให้ถูกต้อง ทำรายการที่ปุ่มแก้ไข
            <v-btn fab small dark color="warning">
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </div>
          <div class="font-weight-bold">
            <v-icon color="amber">mdi-checkbox-blank</v-icon>
            ยังไม่ได้กำหนดประเภทวิชา สาขาวิชา ทำรายการที่ปุ่มแก้ไข
            <v-btn fab small dark color="warning">
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </div>
          <div>
            * หากไม่กำหนดให้ถูกต้องจะมีผลต่อการคำนวณอัตรากำลังของสถานศึกษา
          </div>
        </v-alert>
        <!--    group-by="personnel_etc_m_type" -->
        <v-select
          v-model="selectedHeaders"
          :items="headers"
          label="เลือกคอลัมน์ที่ต้องการแสดง"
          multiple
          outlined
          return-object
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 6">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 6" class="grey--text caption"
              >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
            >
          </template>
        </v-select>
        <v-data-table
          :loading="loading"
          :headers="showHeaders"
          :items="personnel_etcs"
          :search="search"
          :item-class="row_classes"
          disable-pagination
        >
          <template v-slot:item.personnel_etc_m_type="{ item }">
            <span
              class="warning--text font-weight-bold"
              v-if="item.personnel_etc_m_type === 'permanent'"
              >ลูกจ้างประจำ</span
            >
            <span
              class="warning--text font-weight-bold"
              v-else-if="item.personnel_etc_m_type === 'governmentteach'"
              >พนักงานราชการ(สายการสอน)</span
            >
            <span
              class="warning--text font-weight-bold"
              v-else-if="item.personnel_etc_m_type === 'governmentsp'"
              >พนักงานราชการ(สายสนับสนุน)</span
            >
            <span
              class="purple--text font-weight-bold"
              v-else-if="item.personnel_etc_m_type === 'temporary'"
              >ครูอัตราจ้าง</span
            >
            <span
              class="purple--text font-weight-bold"
              v-else-if="item.personnel_etc_m_type === 'temporary_job'"
              >ลูกจ้างชั่วคราว</span
            >
            <span
              class="primary--text font-weight-bold"
              v-else-if="item.personnel_etc_m_type === 'teacher'"
              >ข้าราชการครู</span
            >
            <span
              class="info--text font-weight-bold"
              v-else-if="item.personnel_etc_m_type === 'se_director'"
              >รองผู้อำนวยการ</span
            >
            <span
              class="info--text font-weight-bold"
              v-else-if="item.personnel_etc_m_type === 'director'"
              >ผู้อำนวยการ</span
            >
            <span
              class="primary--text font-weight-bold"
              v-else-if="item.personnel_etc_m_type === '38'"
              >38 ค. (2)</span
            >
            <span class="red--text font-weight-bold" v-else
              >ยังไม่ได้กำหนดปะเภท</span
            >
          </template>

          

  <template v-slot:item.personnel_etc_idplan="{ item }">
           <v-chip color="primary"  v-if="item.personnel_etc_idplan==='update'"> <v-icon>mdi-checkbox-marked-circle</v-icon> ID PLAN</v-chip>
          </template>

          <template v-slot:item.personnel_etc_salary="{ item }">
            {{ Number(item.personnel_etc_salary).toLocaleString() }}
          </template>

          <template v-slot:item.personnel_etc_subject="{ item }">
            <div
              v-if="
                item.personnel_etc_m_type === 'governmentteach' ||
                  item.personnel_etc_m_type === 'temporary' ||
                  item.personnel_etc_m_type === 'teacher'
              "
              class="text-center"
            >
              <v-chip v-if="item.personnel_etc_subject" color="primary">
               {{ item.subject_typeName }}
              </v-chip>
              <v-chip v-else color="warning">
                กรุณาเลือกประเภทวิชา
              </v-chip>
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              fab
              small
              dark
              color="warning"
              @click="personnel_etcEdit(item.personnel_etc_id_card)"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:item.action_s="{ item }">
            <v-btn
              fab
              small
              dark
              color="red"
              @click="personnel_etcDelete(item.personnel_etc_id_card)"
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!--addpersonnel_etcdialog  -->
      <v-layout row justify-center>
        <v-dialog
          v-model="addpersonnel_etcdialog"
          persistent
          max-width="80%"
          overlay-opacity="0.6"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-clipboard-text"
              title="เพิ่มข้อมูล ลูกจ้างประจำ พนักงานราชการ ครูอัตราจ้างหรือลูกจ้างชั่วคราว"
              class="px-5 py-3"
            ></base-material-card>
            <v-form ref="addpersonnel_etcform" lazy-validation>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex md12>
                    <v-alert
                      colored-border
                      type="info"
                      elevation="2"
                      class="pa-0"
                    >
                      <v-row no-gutters>
                        <v-col cols="12" md="8" class="pa-1">
                          <v-select
                            v-model="addpersonnel_etc.typeNationalityS"
                            :items="typeNationality"
                            item-text="text"
                            item-value="value"
                            label="เลือกสัญชาติ"
                            outlined
                            :rules="[v => !!v || '']"
                          ></v-select>

                          <v-text-field
                            v-if="addpersonnel_etc.typeNationalityS === '0'"
                            @input="validateInput()"
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_id_card"
                            label="ค้นหาข้าราชการจากระบบหลัก : รหัสบัตรประชาชน"
                            :rules="[
                              v => (!!v && v.length == 13) || '13  ID Card',
                              validateInputcheck === 'true' ||
                                'กำหนดรหัสบัตรประชาชนไม่ถูกต้อง'
                            ]"
                            maxlength="13"
                          ></v-text-field>
                          <span class="red--text font-weight-bold"
                            >*
                            ปุ่มค้นหาจะปรากฎเมื่อกรอกหมายเลขบัตรประชาชนถูกต้อง</span
                          >
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-btn
                            v-if="
                              validateInputcheck === 'true' &&
                                addpersonnel_etc.typeNationalityS === '0'
                            "
                            rounded
                            color="info"
                            @click="personnel_temporaryQuery()"
                          >
                            <v-icon>mdi-account-search</v-icon> ค้นหา
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-alert>
                    <v-alert
                      colored-border
                      color="#34CDC1"
                      elevation="2"
                      class="pa-2"
                    >
                      <div class="text-center pa-5">
                        <span class="primary--text font-weight-bold">
                          เพิ่มข้อมูล ลูกจ้างประจำ พนักงานราชการ และ
                          ลูกจ้างชั่วคราว</span
                        >
                      </div>

                      <v-row>
                        <v-col cols="12" md="12">
                          <v-select
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_m_type"
                            :items="type_personnel"
                            item-text="text"
                            item-value="value"
                            label="ประเภทบุคลากร"
                            :rules="[v => !!v || '']"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <hr />
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-if="addpersonnel_etc.typeNationalityS === '0'"
                            @input="validateInput()"
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_id_card"
                            label="รหัสบัตรประชาชน"
                            :rules="[
                              v => !!v || 'This field is required',
                              v => (!!v && v.length == 13) || '13  ID Card',
                              validateInputcheck === 'true' ||
                                'กำหนดรหัสบัตรประชาชนไม่ถูกต้อง'
                            ]"
                            maxlength="13"
                          ></v-text-field>

                          <v-text-field
                            v-if="addpersonnel_etc.typeNationalityS === '1'"
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_id_card"
                            label="รหัสบัตรประชาชน"
                            :rules="[v => !!v || 'This field is required']"
                            maxlength="13"
                          ></v-text-field>

                          <v-autocomplete
                            :items="prefixs"
                            item-text="prefix_name"
                            item-value="prefix_name"
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_title"
                            label="คำนำหน้าชื่อ"
                            :rules="[v => !!v || '']"
                          ></v-autocomplete>
                          <v-text-field
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_fristname"
                            label="ชื่อ"
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_lastname"
                            label="นามสกุล"
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-autocomplete
                            :items="workgroups"
                            item-text="workGroupName"
                            item-value="workGroupID"
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_workgroup"
                            label="งาน"
                            v-if="
                              addpersonnel_etc.personnel_etc_m_type ===
                                'permanent' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'governmentsp' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'temporary_job'
                            "
                          ></v-autocomplete>

                          <v-text-field
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_id_position"
                            label="เลขที่ตำแหน่ง"
                            v-if="
                              addpersonnel_etc.personnel_etc_m_type ===
                                'permanent' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'director' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'se_director' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'teacher' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'supervision' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'governmentteach' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'governmentsp'
                            "
                          ></v-text-field>

                          <v-select
                            :items="rang_levels"
                            item-text="text"
                            item-value="value"
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_level"
                            v-if="
                              addpersonnel_etc.personnel_etc_m_type ===
                                'permanent' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'director' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'se_director' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'teacher' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'supervision'
                            "
                            label="อันดับ/ระดับ"
                          ></v-select>
                          <v-select
                            :items="rang_names"
                            item-text="text"
                            item-value="value"
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_rang"
                            v-if="
                              addpersonnel_etc.personnel_etc_m_type ===
                                'permanent' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'director' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'se_director' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'teacher' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'supervision'
                            "
                            label="วิทยฐานะ"
                          ></v-select>
                          <v-text-field
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_type"
                            v-if="
                              addpersonnel_etc.personnel_etc_m_type ===
                                'governmentteach' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'governmentsp'
                            "
                            label="ประเภท"
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_about"
                            v-if="
                              addpersonnel_etc.personnel_etc_m_type ===
                                'governmentteach' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'governmentsp'
                            "
                            label="ด้าน"
                          ></v-text-field>
                          <v-select
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_budget"
                            v-if="
                              addpersonnel_etc.personnel_etc_m_type ===
                                'temporary' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'temporary_job'
                            "
                            label="งบประมาณ"
                            :items="budget_etcs"
                            item-text="budget_etcdetail"
                            item-value="budget_etcid"
                          ></v-select>
                          <v-text-field
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_salary"
                            label="อัตราเงินเดือน"
                            type="number"
                          ></v-text-field>

                          <v-autocomplete
                            v-if="
                              addpersonnel_etc.personnel_etc_m_type ===
                                'teacher' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'governmentteach' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'temporary'
                            "
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_subject"
                            :items="subject_types"
                            item-text="subject_typeName"
                            item-value="subject_typeID"
                            label="ประเภทวิชา"
                            @change="subject_typesSearchAdd()"
                          >
                            <template v-slot:item="{ item }">
                              <div>
                                <div style="font-weight: bold;">
                                  {{ item.subject_typeID }} :
                                  {{ item.subject_typeName }}
                                </div>
                              </div>
                            </template>
                          </v-autocomplete>

                          <v-autocomplete
                            v-if="
                              addpersonnel_etc.personnel_etc_m_type ===
                                'teacher' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'governmentteach' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'temporary'
                            "
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_branch"
                            :items="rate_work_course_stds"
                            :item-text="
                              addpersonnel_etc.personnel_etc_subject === '66'
                                ? 'short_courseSTBranch'
                                : 'branch_name_th'
                            "
                            :item-value="
                              addpersonnel_etc.personnel_etc_subject === '66'
                                ? 'short_courseSTBranchID'
                                : 'id_course_branch'
                            "
                            label="สาขาวิชา (เฉพาะผู้ที่ทำหน้าที่สอน)"
                            @change="rate_work_course_stdSubBrach()"
                          >
                            <template v-slot:item="{ item }">
                              <div>
                                <div style="font-weight: bold;">
                                  <span
                                    v-if="
                                      addpersonnel_etc.personnel_etc_subject ===
                                        '66'
                                    "
                                  >
                                    {{ item.short_courseSTBranchID }} :
                                    {{ item.short_courseSTBranch }}</span
                                  >
                                  <span v-else>
                                    {{ item.year_course }} :
                                    {{ item.branch_name_th }}</span
                                  >
                                </div>
                                <div style="">
                                  <span
                                    v-if="
                                      addpersonnel_etc.personnel_etc_subject ===
                                        '66'
                                    "
                                    >{{ item.short_courseGroupName }}</span
                                  >
                                  <span v-else>
                                    ประเภทวิชา : {{ item.type_course }}</span
                                  >
                                </div>
                              </div>
                            </template>
                          </v-autocomplete>
                          <v-autocomplete
                            v-if="
                              addpersonnel_etc.personnel_etc_m_type ===
                                'teacher' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'governmentteach' ||
                                addpersonnel_etc.personnel_etc_m_type ===
                                  'temporary'
                            "
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_branch_sub"
                            :items="rate_work_course_stdSubs"
                            :item-text="
                              addpersonnel_etc.personnel_etc_subject === '66'
                                ? 'short_courseName'
                                : 'branch_sub_name_th'
                            "
                            :item-value="
                              addpersonnel_etc.personnel_etc_subject === '66'
                                ? 'short_courseID'
                                : 'id_course_branch_sub'
                            "
                            label="สาขางาน (เฉพาะผู้ที่ทำหน้าที่สอน)"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_education_1"
                            label="วุฒิการศึกษา(สาขาวิชา) ต่ำกว่า ป.ตรี"
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_education_2"
                            label="วุฒิการศึกษา(สาขาวิชา) ปริญญาตรี"
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_education_3"
                            label="วุฒิการศึกษา(สาขาวิชา) ปริญญาโท"
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_education_4"
                            label="วุฒิการศึกษา(สาขาวิชา) ปริญญาเอก"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-flex>
                </v-layout>
                <v-spacer></v-spacer>
                <hr />
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                    v-if="validateInputcheck === 'false'"
                  >
                    <div class="text-right red--text font-weight-bold">
                      กำหนดรหัสบัตรประชาชนไม่ถูกต้อง ไม่สามารถบันทึกข้อมูลได้
                    </div>
                  </v-col>
                  <v-col cols="12" lg="12" class="text-right">
                    <v-btn
                      outlined
                      color="warning"
                      @click.stop="addpersonnel_etcdialog = false"
                      rounded
                      class="mr-2"
                    >
                      <v-icon dark>mdi-close</v-icon>ยกเลิก
                    </v-btn>

                    <v-btn
                      v-if="
                        validateInputcheck === 'true' ||
                          addpersonnel_etc.typeNationalityS === '1'
                      "
                      color="success"
                      @click.stop="addpersonnel_etcSubmit()"
                      rounded
                    >
                      <v-icon dark>mdi-content-save</v-icon
                      >&nbsp;&nbsp;บันทึก</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model searchPersonnel_etcdialog -->
      <v-layout>
        <v-dialog
          v-model="searchPersonnel_etcdialog"
          persistent
          max-width="50%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="success"
              icon="mdi-account-check"
              title="ค้นพบข้อมูล"
              class="px-5 py-3 "
            ></base-material-card>
            <v-card-text class="">
              <v-form ref="searchPersonnel_etcdialogform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      ยืนยันการนำเข้าข้อมูล :
                      <v-list>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-account-check
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title
                              >รหัสบัตรประชาชน</v-list-item-title
                            >
                            <v-list-item-subtitle>{{
                              personnel_temporarys.id_card
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider inset></v-divider>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-account-card-details
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>ชื่อ-นามสกุล</v-list-item-title>
                            <v-list-item-subtitle
                              >{{ personnel_temporarys.title_s
                              }}{{ personnel_temporarys.frist_name }}
                              {{ personnel_temporarys.last_name }}

                              เลขที่ตำแหน่ง
                              {{ personnel_temporarys.id_position }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>

                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-map-marker
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>ปัจจุบัน</v-list-item-title>
                            <v-list-item-subtitle>{{
                              personnel_temporarys.college_name
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-flex>
                    <v-flex xs12>
                      <v-autocomplete
                        v-if="
                          personnel_temporarys.user_status === 'teacher' ||
                            personnel_temporarys.user_status ===
                              'governmentteach' ||
                            personnel_temporarys.user_status === 'temporary'
                        "
                        outlined
                        v-model="addpersonnel_etc.personnel_etc_subject"
                        :items="subject_types"
                        item-text="subject_typeName"
                        item-value="subject_typeID"
                        label="ประเภทวิชา"
                        :rules="[v => !!v || '']"
                        @change="subject_typesSearchEditSearch()"
                      >
                        <template v-slot:item="{ item }">
                          <div>
                            <div style="font-weight: bold;">
                              {{ item.subject_typeID }} :
                              {{ item.subject_typeName }}
                            </div>
                          </div>
                        </template>
                      </v-autocomplete>

                      <v-autocomplete
                        v-if="
                          personnel_temporarys.user_status === 'teacher' ||
                            personnel_temporarys.user_status ===
                              'governmentteach' ||
                            personnel_temporarys.user_status === 'temporary'
                        "
                        outlined
                        v-model="addpersonnel_etc.personnel_etc_branch"
                        :items="rate_work_course_stds"
                        :item-text="
                          addpersonnel_etc.personnel_etc_subject === '66'
                            ? 'short_courseSTBranch'
                            : 'branch_name_th'
                        "
                        :item-value="
                          addpersonnel_etc.personnel_etc_subject === '66'
                            ? 'short_courseSTBranchID'
                            : 'id_course_branch'
                        "
                        label="สาขาวิชา (เฉพาะผู้ที่ทำหน้าที่สอน)"
                        :rules="[v => !!v || '']"
                        @change="rate_work_course_stdSubBrachEdit()"
                      >
                        <template v-slot:item="{ item }">
                          <div>
                            <div style="font-weight: bold;">
                              <span
                                v-if="
                                  addpersonnel_etc.personnel_etc_subject ===
                                    '66'
                                "
                              >
                                {{ item.short_courseSTBranchID }} :
                                {{ item.short_courseSTBranch }}</span
                              >
                              <span v-else>
                                {{ item.year_course }} :
                                {{ item.branch_name_th }}</span
                              >
                            </div>
                            <div style="">
                              <span
                                v-if="
                                  addpersonnel_etc.personnel_etc_subject ===
                                    '66'
                                "
                                >{{ item.short_courseGroupName }}</span
                              >
                              <span v-else>
                                ประเภทวิชา : {{ item.type_course }}</span
                              >
                            </div>
                          </div>
                        </template>
                      </v-autocomplete>
                      <v-autocomplete
                        v-if="
                          personnel_temporarys.user_status === 'teacher' ||
                            personnel_temporarys.user_status ===
                              'governmentteach' ||
                            personnel_temporarys.user_status === 'temporary'
                        "
                        outlined
                        v-model="addpersonnel_etc.personnel_etc_branch_sub"
                        :items="rate_work_course_stdSubs"
                        :item-text="
                          addpersonnel_etc.personnel_etc_subject === '66'
                            ? 'short_courseName'
                            : 'branch_sub_name_th'
                        "
                        :item-value="
                          addpersonnel_etc.personnel_etc_subject === '66'
                            ? 'short_courseID'
                            : 'id_course_branch_sub'
                        "
                        label="สาขางาน (เฉพาะผู้ที่ทำหน้าที่สอน)"
                      ></v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn rounded @click.stop="searchPersonnel_etcdialog = false">
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                rounded
                color="green"
                @click.stop="searchPersonnel_etcdialogSubmit()"
                dark
              >
                <v-icon dark>mdi-content-save-settings</v-icon
                >&nbsp;ยืนยันการนำเข้าข้อมูล
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deletepersonnel_etcdialog -->
      <v-layout>
        <v-dialog
          v-model="deletepersonnel_etcdialog"
          persistent
          max-width="40%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูล"
              class="px-5 py-3 "
            ></base-material-card>
            <v-card-text class="">
              <v-form ref="deletepersonnel_etcform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      ยืนยันการลบข้อมูล :
                      <h3>{{ editpersonnel_etc.personnel_etc_id_card }}</h3>
                    </v-flex>
                    <v-flex xs12 md6
                      >{{ editpersonnel_etc.personnel_etc_title
                      }}{{ editpersonnel_etc.personnel_etc_fristname }}
                      {{ editpersonnel_etc.personnel_etc_lastname }}</v-flex
                    >
                    <v-flex xs12 md6></v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click.stop="deletepersonnel_etcdialog = false">
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                color="red darken-3"
                @click.stop="deletepersonnel_etcubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editpersonnel_etcdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="editpersonnel_etcdialog" persistent max-width="80%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลบุคลากร"
              class="px-5 py-3 "
            ></base-material-card>
            <v-card-text>
              <v-form ref="editpersonnel_etcform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-row no-gutters>
                        <v-col cols="12" md="12">
                          <v-select
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'teacher' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'se_director' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'director' ||
                                editpersonnel_etc.personnel_etc_m_type === '38'
                            "
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_m_type"
                            :items="type_personnelGov"
                            item-text="text"
                            item-value="value"
                            label="ประเภทบุคลากร"
                            :rules="[v => !!v || '']"
                          ></v-select>

                          <v-select
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'permanent' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentteach' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'government' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentsp' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'temporary' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'temporary_job' ||
                                editpersonnel_etc.personnel_etc_m_type === null
                            "
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_m_type"
                            :items="type_personnel"
                            item-text="text"
                            item-value="value"
                            label="ประเภทบุคลากร"
                            :rules="[v => !!v || '']"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <hr />
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-select
                            v-model="editpersonnel_etc.typeNationalityS"
                            :items="typeNationality"
                            item-text="text"
                            item-value="value"
                            label="เลือกสัญชาติ"
                            outlined
                            :rules="[v => !!v || '']"
                          ></v-select>

                          <v-text-field
                            v-if="editpersonnel_etc.typeNationalityS === '0'"
                            @input="validateInput()"
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_id_card"
                            label="รหัสบัตรประชาชน"
                            :rules="[
                              v => !!v || 'This field is required',
                              v => (!!v && v.length == 13) || '13  ID Card'
                            ]"
                            readonly
                          ></v-text-field>

                          <v-text-field
                            v-if="editpersonnel_etc.typeNationalityS === '1'"
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_id_card"
                            label="รหัสบัตรประชาชน"
                            :rules="[v => !!v || 'This field is required']"
                            readonly
                          ></v-text-field>

                          <v-autocomplete
                            :items="prefixs"
                            item-text="prefix_name"
                            item-value="prefix_name"
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_title"
                            label="คำนำหน้าชื่อ"
                            :rules="[v => !!v || '']"
                          ></v-autocomplete>
                          <v-text-field
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_fristname"
                            label="ชื่อ"
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_lastname"
                            label="นามสกุล"
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-autocomplete
                            :items="workgroups"
                            item-text="workGroupName"
                            item-value="workGroupID"
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_workgroup"
                            label="งาน"
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'permanent' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentteach' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentsp' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'temporary_job'
                            "
                          ></v-autocomplete>

                          <v-text-field
                            outlined
                            v-model="
                              editpersonnel_etc.personnel_etc_id_position
                            "
                            label="เลขที่ตำแหน่ง"
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'permanent' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'director' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'se_director' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'teacher' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'supervision' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentteach' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentsp'
                            "
                          ></v-text-field>

                          <v-select
                            :items="rang_levels"
                            item-text="text"
                            item-value="value"
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_level"
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'permanent' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'director' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'se_director' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'teacher' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'supervision'
                            "
                            label="อันดับ/ระดับ"
                          ></v-select>
                          <v-select
                            :items="rang_names"
                            item-text="text"
                            item-value="value"
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_rang"
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'permanent' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'director' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'se_director' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'teacher' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'supervision'
                            "
                            label="วิทยฐานะ"
                          ></v-select>
                          <v-text-field
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_type"
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'government'
                            "
                            label="ประเภท"
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_about"
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'government'
                            "
                            label="ด้าน"
                          ></v-text-field>
                          <v-select
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_budget"
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'temporary' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'temporary_job'
                            "
                            label="งบประมาณ"
                            :items="budget_etcs"
                            item-text="budget_etcdetail"
                            item-value="budget_etcid"
                          ></v-select>
                          <v-text-field
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_salary"
                            label="อัตราเงินเดือน"
                            type="number"
                          ></v-text-field>

                          <v-autocomplete
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'teacher' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentteach' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentsp' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'temporary'
                            "
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_subject"
                            :items="subject_types"
                            item-text="subject_typeName"
                            item-value="subject_typeID"
                            label="ประเภทวิชา"
                            @change="personnel_etc_subjectEdit()"
                          >
                            <template v-slot:item="{ item }">
                              <div>
                                <div style="font-weight: bold;">
                                  {{ item.subject_typeID }} :
                                  {{ item.subject_typeName }}
                                </div>
                              </div>
                            </template>
                          </v-autocomplete>

                          <v-autocomplete
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'teacher' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentteach' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentsp' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'temporary'
                            "
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_branch"
                            :items="rate_work_course_stds"
                            :item-text="
                              editpersonnel_etc.personnel_etc_subject === '66'
                                ? 'short_courseSTBranch'
                                : 'branch_name_th'
                            "
                            :item-value="
                              editpersonnel_etc.personnel_etc_subject === '66'
                                ? 'short_courseSTBranchID'
                                : 'id_course_branch'
                            "
                            label="สาขาวิชา (เฉพาะผู้ที่ทำหน้าที่สอน)"
                            @change="personnel_etc_branchEdit()"
                          >
                            <template v-slot:item="{ item }">
                              <div>
                                <div style="font-weight: bold;">
                                  <span
                                    v-if="
                                      editpersonnel_etc.personnel_etc_subject ===
                                        '66'
                                    "
                                  >
                                    {{ item.short_courseSTBranchID }} :
                                    {{ item.short_courseSTBranch }}</span
                                  >
                                  <span v-else>
                                    {{ item.year_course }} :
                                    {{ item.branch_name_th }}</span
                                  >
                                </div>
                                <div style="">
                                  <span
                                    v-if="
                                      editpersonnel_etc.personnel_etc_subject ===
                                        '66'
                                    "
                                    >{{ item.short_courseGroupName }}</span
                                  >
                                  <span v-else>
                                    ประเภทวิชา : {{ item.type_course }}</span
                                  >
                                </div>
                              </div>
                            </template>
                          </v-autocomplete>
                          <v-autocomplete
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'teacher' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentteach' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentsp' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'temporary'
                            "
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_branch_sub"
                            :items="rate_work_course_stdSubs"
                            :item-text="
                              editpersonnel_etc.personnel_etc_subject === '66'
                                ? 'short_courseName'
                                : 'branch_sub_name_th'
                            "
                            :item-value="
                              editpersonnel_etc.personnel_etc_subject === '66'
                                ? 'short_courseID'
                                : 'id_course_branch_sub'
                            "
                            label="สาขางาน (เฉพาะผู้ที่ทำหน้าที่สอน)"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            outlined
                            v-model="
                              editpersonnel_etc.personnel_etc_education_1
                            "
                            label="วุฒิการศึกษา(สาขาวิชา) ต่ำกว่า ป.ตรี"
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="
                              editpersonnel_etc.personnel_etc_education_2
                            "
                            label="วุฒิการศึกษา(สาขาวิชา) ปริญญาตรี"
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="
                              editpersonnel_etc.personnel_etc_education_3
                            "
                            label="วุฒิการศึกษา(สาขาวิชา) ปริญญาโท"
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="
                              editpersonnel_etc.personnel_etc_education_4
                            "
                            label="วุฒิการศึกษา(สาขาวิชา) ปริญญาเอก"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-text class="red--text">
              * หากหมายเลขบัตรประชาชนผิดจากการกรอกข้อมูลของสถานศึกษา ให้ดำเนินการลบรายการ และเพิ่มข้อมูลใหม่
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click.stop="editpersonnel_etcdialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                color="warning"
                @click.stop="editpersonnel_etcSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;แก้ไขข้อมูล
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model personnel_etcCSVdialog -->
      <v-layout>
        <v-dialog v-model="personnel_etcCSVdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-content-paste"
              title="นำเข้าข้อมูลบุคลากร"
              class="px-5 py-3"
            >
            </base-material-card>

            <v-card-text>
              <v-card>
                <v-card-text>
                  <v-form ref="personnel_etcCSVdialogform" lazy-validation>
                    <v-container grid-list-md>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-btn
                            outlined
                            block
                            color="info"
                            :to="
                              '/college/personnel_etc_import/' +
                                user.user_code
                            "
                            target="_blank"
                          >
                            <v-icon>mdi-exit-to-app</v-icon
                            >ส่งออกแบบฟอร์มบันทึกข้อมูล</v-btn
                          >
                        </v-col>
                        <v-col cols="12" md="12">
                          <h2>
                            <v-file-input
                              v-model="file2"
                              accept=".csv"
                              name="csvFiles"
                              color="deep-purple accent-4"
                              counter
                              label="ไฟล์ .csv กรุณา Save as Type: CSV UTF-8 (Comma delimited)"
                              placeholder="ไฟล์ CSV ข้อมูลบุคลากร Save as Type: CSV UTF-8 (Comma delimited)"
                              prepend-icon="mdi-paperclip"
                              outlined
                              rounded
                              :show-size="1000"
                              :rules="[v => !!v || '']"
                            >
                              <template v-slot:selection="{ index, text }">
                                <v-chip
                                  v-if="index < 2"
                                  color="deep-purple accent-4"
                                  dark
                                  label
                                  small
                                >
                                  {{ text }}
                                </v-chip>

                                <span
                                  v-else-if="index === 2"
                                  class="text-overline grey--text text--darken-3 mx-2"
                                >
                                  +{{ files.length - 2 }} File(s)
                                </span>
                              </template>
                            </v-file-input>
                          </h2>

                          <div class="red--text ma-5 text-center">
                            กรุณาตรวจสอบฟิลล์ รหัสบัตรประชาชน ให้อยู่ในรูปแบบ
                            Custom Type : 0
                          </div>

                          <v-btn
                            class="pa-2"
                            block
                            color="green"
                            @click.stop="csvpersonnel_etcSubmit()"
                          >
                            <v-icon>mdi-book-plus</v-icon>
                            นำเข้า</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  color="grey"
                  @click.stop="personnel_etcCSVdialog = false"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- Video Dialog -->
      <v-layout>
        <v-dialog
          v-model="VideoDialogYoutubeConditionbranch"
          persistent
          max-width="80%"
        >
          <v-card class="pa-0">
            <iframe
              width="100%"
              height="600px"
              src="https://www.youtube.com/embed/AGr0Gx0hxME"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                rounded
                @click.stop="VideoDialogYoutubeConditionbranch = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        multi-line
        vertical
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon>{{ snackbar.icon }}</v-icon>
        <br />
        {{ snackbar.text }}
        <v-btn dark @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ApiKey: "HRvec2021",
      loading: false,
      updateImageDialog: false,
      search: "",
      searchIDcard: "",
      addpersonnel_etcdialog: false,
      editpersonnel_etcdialog: false,
      deletepersonnel_etcdialog: false,
      searchPersonnel_etcdialog: false,
      showimagedialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      currentPK: null,
      headers: [],
      selectedHeaders: [],
      headersMap: [
        { text: "ประเภท", class: "center", value: "personnel_etc_m_type" },
        {
          text: "แก้ไข",
          class: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        {
          text: "ลบ",
          class: "center",
          value: "action_s",
          icon: "mdi-delete-forever"
        },
        
        { text: "ID PLAN", class: "center", value: "personnel_etc_idplan" },
        { text: "รหัส", class: "center", value: "personnel_etc_id_card" },
        { text: "คำนำหน้าชื่อ", class: "center", value: "personnel_etc_title" },
        { text: "ชื่อ", class: "center", value: "personnel_etc_fristname" },
        { text: "นามสกุล", class: "center", value: "personnel_etc_lastname" },

        {
          text: "เลขที่ตำแหน่ง",
          class: "center",
          value: "personnel_etc_id_position"
        },
        { text: "อันดับ/ระดับ", class: "center", value: "personnel_etc_level" },
        { text: "วิทยฐานะ", class: "center", value: "personnel_etc_rang" },
        { text: "ประเภท", class: "center", value: "personnel_etc_type" },
        { text: "ด้าน", class: "center", value: "personnel_etc_about" },
        {
          text: "เงินที่ใช้จ้าง",
          class: "center",
          value: "budget_etcdetail"
        },
        {
          text: "เงินเดือน",
          class: "center",
          value: "personnel_etc_salary"
        },
        {
          text: "ต่ำกว่า ป.ตรี",
          class: "text-left",
          value: "personnel_etc_education_1"
        },
        {
          text: "ปริญญาตรี",
          class: "text-left",
          value: "personnel_etc_education_2"
        },
        {
          text: "ปริญญาโท",
          class: "text-left",
          value: "personnel_etc_education_3"
        },
        {
          text: "ปริญญาเอก",
          class: "text-left",
          value: "personnel_etc_education_4"
        },
        {
          text: "ประเภทวิชา",
          class: "text-left",
          value: "personnel_etc_subject"
        },
        {
          text: "สาขาวิชา",
          class: "text-left",
          value: "personnel_etc_branch_n"
        },

        {
          text: "สาขาวิชางาน",
          class: "text-left",
          value: "personnel_etc_branch_Subn"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      pagination: {},
      personnel_etc: [],
      addpersonnel_etc: {},
      editpersonnel_etc: [],
      personnel_etcs: [],
      personnel_etc_sub: [],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      colleges: {},
      provinces: [],
      prefectures: [],
      regions: [],
      region_ena: true,
      rate_work_course_stds: [],
      rate_work_course_stdSubs: [],

      type_personnelGov: [
        { text: "ครู", value: "teacher" },
        { text: "รองผู้อำนวยการ", value: "se_director" },
        { text: "ผู้อำนวยการ", value: "director" },
        { text: "38 ค.(2)", value: "38" }
      ],

      type_personnel: [
        { text: "ครู", value: "teacher" },
        { text: "รองผู้อำนวยการ", value: "se_director" },
        { text: "ผู้อำนวยการ", value: "director" },
        { text: "38 ค.(2)", value: "38" },
        { text: "ลูกจ้างประจำ", value: "permanent" },
        { text: "พนักงานราชการ(สายการสอน)", value: "governmentteach" },
        { text: "พนักงานราชการ(สายสนับสนุน)", value: "governmentsp" },
        { text: "ครูอัตราจ้าง", value: "temporary" },
        { text: "ลูกจ้างชั่วคราว", value: "temporary_job" }
      ],
      personnel_etcsCounts: [],
      personnel_temporary: [],
      personnel_temporarys: [],
      searchadd_temporary: {},
      user: [],
      workgroups: [],
      subject_types: [],
      personnel_etcSYSs: [],
      addpersonnel_req: {},
      personnel_reqReqNTB: [],
      personnel_reqReqOTB: [],
      personnel_reqHearder: [
        { text: "ชื่อ-นามสกุล", class: "center", value: "personel_reqIDcard" },
        { text: "สังกัดเดิม", class: "center", value: "oldCollege" },
        { text: "วันที่ข้อมูล", class: "center", value: "personel_reqDate" },
        { text: "สถานะ", class: "center", value: "personel_reqStatus" },
        { text: "ยกเลิก", class: "center", value: "action" }
      ],
      personnel_reqOHearder: [
        { text: "ชื่อ-นามสกุล", class: "center", value: "personel_reqIDcard" },
        { text: "สังกัดใหม่", class: "center", value: "newCollege" },
        { text: "วันที่ข้อมูล", class: "center", value: "personel_reqDate" },
        { text: "สถานะ", class: "center", value: "personel_reqStatus" },
        { text: "ยกเลิก", class: "center", value: "action" }
      ],
      personnel_reqReqAp: [],
      personnel_reqReq_Pect: {},
      personnel_reqReq_DelPreq: {},
      validateInputcheck: [],
      personnel_etcCSVdialog: false,
      file2: null,
      VideoDialogYoutubeConditionbranch: false,
      prefixs: [],
      typeNationality: [
        { text: "สัญชาติไทย", value: "0" },
        { text: "สัญชาติอื่นๆ", value: "1" }
      ],
      data_syslog: {},
      rang_names: [
        { text: "-", value: "-" },
        { text: "ต้น", value: "ต้น" },
        { text: "ปฏิบัติงาน", value: "ปฏิบัติงาน" },
        { text: "ปฏิบัติการ", value: "ปฏิบัติการ" },
        { text: "ครูผู้ช่วย", value: "ครูผู้ช่วย" },
        { text: "ครู", value: "ครู" },
        { text: "ชำนาญงาน", value: "ชำนาญงาน" },
        { text: "ชำนาญการ", value: "ชำนาญการ" },
        { text: "ชำนาญการพิเศษ", value: "ชำนาญการพิเศษ" },
        { text: "เชี่ยวชาญ", value: "เชี่ยวชาญ" },
        { text: "เชี่ยวชาญพิเศษ", value: "เชี่ยวชาญพิเศษ" }
      ],
      rang_levels: [
        { text: "-", value: "-" },
        { text: "ครูผู้ช่วย", value: "ครูผู้ช่วย" },
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" }
      ],
      budget_etcs: []
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },
  async mounted() {
    await this.workgroupQuery();
    await this.personnel_etcsQueryAll();
    await this.rate_work_course_stdsQueryAll();
    await this.personnel_etcsCount();
    await this.subject_typeQuery();
    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
    let result = await this.$http.post("user.php", {
      ApiKey: this.ApiKey,
      user_ID: userSession.user_ID
    });
    this.user = result.data;
    
    await this.personnel_reqReqNTBQuery();
    await this.personnel_reqReqOTBQuery();
    await this.prefixQuery();
    await this.budget_etcsQuery();
  },

  methods: {
    async budget_etcsQuery() {
      let result = await this.$http.post("budget_etc.php", {
        ApiKey: this.ApiKey
      });
      this.budget_etcs = result.data;
    },

    async prefixQuery() {
      let result = await this.$http.post("prefix.php", {
        ApiKey: this.ApiKey
      });
      this.prefixs = result.data;
    },

    async VideoDialogConditionB() {
      this.VideoDialogYoutubeConditionbranch = true;
    },

    async cancelReg(personel_reqNewCollege, personel_reqIDcard) {
      Swal.fire({
        title: "ยกเลิกรายการร้องขอ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          await this.sweetAlertLoading();
          this.personnel_reqReq_DelPreq.ApiKey = this.ApiKey;
          this.personnel_reqReq_DelPreq.personel_reqNewCollege = personel_reqNewCollege;
          this.personnel_reqReq_DelPreq.personel_reqIDcard = personel_reqIDcard;

          let resultDel_Preq = await this.$http.post(
            "personnel_req.delete.college.php",
            this.personnel_reqReq_DelPreq
          );

          if (resultDel_Preq.data.status == true) {
            Swal.fire({
              title: "ดำเนินการข้อมูลเรียบร้อย",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            });
            this.personnel_etcsQueryAll();
            this.personnel_reqReqNTBQuery();
            this.personnel_reqReqOTBQuery();
            let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
            this.data_syslog.ApiKey = this.ApiKey;
            this.data_syslog.user_account = userSession.user_name;
            this.data_syslog.event_log = "personnel_req cancel";
            this.data_syslog.page_log = "personnel_etc";
            this.data_syslog.table_log = "personnel_req.delete.college";
            this.data_syslog.detail_log = this.personnel_reqReq_DelPreq.personel_reqIDcard;
            await this.$http.post("data_syslog.insert.php", this.data_syslog);
          } else {
            Swal.fire({
              icon: "warning",
              title: "ผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          Swal.close();
        }
      });
    },

    async personnel_etcCSV() {
      this.personnel_etcCSVdialog = true;
    },

    async csvpersonnel_etcSubmit() {
      let result = "";
      let uploaded = null;
      if (this.file2) {
        let formData = new FormData();
        let filename = this.user.user_code + ".personnel_etc.csv";
        formData.append("file", this.file2);
        formData.append("filename", "../HRvecfiles/" + filename);
        formData.append("ApiKey", this.ApiKey);
        result = await this.$http.post("personnel_etcCSV.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });

        uploaded = true;
      } else {
        uploaded = false;
      }
      if (result.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.personnel_etcsQueryAll();
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
        this.data_syslog.ApiKey = this.ApiKey;
        this.data_syslog.user_account = userSession.user_name;
        this.data_syslog.event_log = "personnel_etc CSV";
        this.data_syslog.page_log = "personnel_etc";
        this.data_syslog.table_log = "personnel_etc.CSV";
        this.data_syslog.detail_log = "CSV";
        await this.$http.post("data_syslog.insert.php", this.data_syslog);
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด กรุณาตรวจสอบรูปแบบไฟล์ การกำหนดบัตรประชา",
          showConfirmButton: false,
          timer: 1500
        });
        this.personnel_etcsQueryAll();
      }
      this.personnel_etcCSVdialog = false;
    },

    async personnel_reqApprove(personel_reqIDcard) {
      let result = await this.$http.post("personnel_req.php", {
        ApiKey: this.ApiKey,
        personel_reqIDcard: personel_reqIDcard
      });
      this.personnel_reqReqAp = result.data;
      let personnelApp =
        this.personnel_reqReqAp.personnel_etc_title +
        this.personnel_reqReqAp.personnel_etc_fristname +
        " " +
        this.personnel_reqReqAp.personnel_etc_lastname;
      Swal.fire({
        title: "อนุมัติย้ายข้อมูลบุคคล",
        text: personnelApp,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          await this.sweetAlertLoading();
          this.personnel_reqReq_Pect.ApiKey = this.ApiKey;
          this.personnel_reqReq_Pect.personnel_etc_id_card = this.personnel_reqReqAp.personel_reqIDcard;
          this.personnel_reqReq_Pect.personnel_etc_college_code = this.personnel_reqReqAp.personel_reqNewCollege;

          this.personnel_reqReq_DelPreq.ApiKey = this.ApiKey;
          this.personnel_reqReq_DelPreq.personel_reqIDcard = this.personnel_reqReqAp.personel_reqIDcard;

          let resultUpdate_Petc = await this.$http.post(
            "personnel_etc.updateMove.php",
            this.personnel_reqReq_Pect
          );

          let resultDel_Preq = await this.$http.post(
            "personnel_req.delete.php",
            this.personnel_reqReq_DelPreq
          );

          if (
            resultDel_Preq.data.status == true &&
            resultUpdate_Petc.data.status == true
          ) {
            Swal.fire({
              title: "ดำเนินการข้อมูลเรียบร้อย",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            });
            this.personnel_etcsQueryAll();
            this.personnel_reqReqNTBQuery();
            this.personnel_reqReqOTBQuery();
            let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
            this.data_syslog.ApiKey = this.ApiKey;
            this.data_syslog.user_account = userSession.user_name;
            this.data_syslog.event_log = "updateMove req";
            this.data_syslog.page_log = "personnel_etc";
            this.data_syslog.table_log = "personnel_etc.updateMove";
            this.data_syslog.detail_log = this.personnel_reqReq_Pect.personnel_etc_id_card;
            this.data_syslog.date_times = this.date_today_log;
            await this.$http.post("data_syslog.insert.php", this.data_syslog);
          } else {
            Swal.fire({
              icon: "warning",
              title: "ผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          Swal.close();
        }
      });
    },

    async personnel_reqReqNTBQuery() {
      let result = await this.$http.post("personnel_req.php", {
        ApiKey: this.ApiKey,
        personel_reqNewCollege: this.user.user_code
      });
      this.personnel_reqReqNTB = result.data;
    },

    async personnel_reqReqOTBQuery() {
      let result = await this.$http.post("personnel_req.php", {
        ApiKey: this.ApiKey,
        personel_reqOldCollege: this.user.user_code
      });
      this.personnel_reqReqOTB = result.data;
    },

    async personnel_reqAdd() {
      if (this.$refs.addpersonnel_reqform.validate()) {
        this.addpersonnel_req.ApiKey = this.ApiKey;
        this.addpersonnel_req.personel_reqNewCollege = this.user.user_code;
        this.addpersonnel_req.personel_reqIDcard = this.personnel_etcSYSs.personnel_etc_id_card;
        this.addpersonnel_req.personel_reqOldCollege = this.personnel_etcSYSs.personnel_etc_college_code;
        this.addpersonnel_req.personel_reqDate = new Date();
        this.addpersonnel_req.personel_reqStatus = "req";


        let result = await this.$http.post(
          "personnel_req.insert.php",
          this.addpersonnel_req
        );
        
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "insert req";
          this.data_syslog.page_log = "personnel_etc";
          this.data_syslog.table_log = "personnel_etc.insert";
          this.data_syslog.detail_log = this.addpersonnel_req.personel_reqIDcard;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.personnel_etcSYSs = [];
        this.searchIDcard = "";
        this.personnel_reqReqNTBQuery();
      }
    },
    checkID() {
      let vauleID = this.addpersonnel_etc.personnel_etc_id_card;
      if (vauleID.length != 13) return false;
      for (i = 0, sum = 0; i < 12; i++)
        sum += parseFloat(vauleID.charAt(i)) * (13 - i);
      if ((11 - (sum % 11)) % 10 != parseFloat(vauleID.charAt(12)))
        return false;
      return true;
    },

    async validateInput() {
      const maxLength = 13;
      const regex = /^[0-9]\d*$/;
      let event = this.addpersonnel_etc.personnel_etc_id_card;
      let value = this.addpersonnel_etc.personnel_etc_id_card;
      let i, sum;
      const char =
        String.fromCharCode(event.keyCode) || String.fromCharCode(event.which);
      if (
        value !== undefined &&
        value.toString().length == maxLength &&
        value.match(regex)
      ) {
        if (value.length != 13) return false;
        for (i = 0, sum = 0; i < 12; i++) {
          sum += parseInt(value.charAt(i)) * (13 - i);
        }
        let mod = sum % 11;
        let check = (11 - mod) % 10;
        if (check == parseInt(value.charAt(12))) {
          this.validateInputcheck = "true";
        }
      } else {
        this.validateInputcheck = "false";
      }
    },

    async validateInput() {
      const maxLength = 13;
      const regex = /^[0-9]\d*$/;
      let event = this.addpersonnel_etc.personnel_etc_id_card;
      let value = this.addpersonnel_etc.personnel_etc_id_card;
      let i, sum;
      const char =
        String.fromCharCode(event.keyCode) || String.fromCharCode(event.which);
      if (
        value !== undefined &&
        value.toString().length == maxLength &&
        value.match(regex)
      ) {
        if (value.length != 13) return false;
        for (i = 0, sum = 0; i < 12; i++) {
          sum += parseInt(value.charAt(i)) * (13 - i);
        }
        let mod = sum % 11;
        let check = (11 - mod) % 10;
        if (check == parseInt(value.charAt(12))) {
          this.validateInputcheck = "true";
        }
      } else {
        this.validateInputcheck = "false";
      }
    },

    async subject_typesSearchAdd() {
      this.rate_work_course_stds = [];
      this.rate_work_course_stdSubs = [];
      let subject = this.addpersonnel_etc.personnel_etc_subject;
      if (subject != "66") {
        this.rate_work_course_stdsQueryAdd();
      } else {
        this.short_courseQueryAdd();
      }
    },

    async subject_typesSearchEdit() {
      this.rate_work_course_stds = [];
      this.rate_work_course_stdSubs = [];
      let subject = this.editpersonnel_etc.personnel_etc_subject;
      if (subject != "66") {
        this.rate_work_course_stdSubBrachEdit();
      } else {
        this.short_courseQueryAdd();
      }
    },
    async subject_typesSearchEditSearch() {
      this.rate_work_course_stds = [];
      this.rate_work_course_stdSubs = [];
      let subject = this.addpersonnel_etc.personnel_etc_subject;
      if (subject != "66") {
        let result = await this.$http.post("rate_work_course_std.php", {
          ApiKey: this.ApiKey,
          id_type_course: this.addpersonnel_etc.personnel_etc_subject
        });
        this.rate_work_course_stds = result.data;
      } else {
        this.short_courseQueryAdd();
      }
    },

    async subject_typeQuery() {
      let result = await this.$http.post("subject_type.php", {
        ApiKey: this.ApiKey
      });
      this.subject_types = result.data;
    },

    async workgroupQuery() {
      let result = await this.$http.post("workgroup.php", {
        ApiKey: this.ApiKey
      });
      this.workgroups = result.data;
    },

    async personnel_temporaryQuery() {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: this.addpersonnel_etc.personnel_etc_id_card
      });
      this.personnel_temporarys = result.data;

      if (this.personnel_temporarys.id_card) {
        this.searchPersonnel_etcdialog = true;
      } else {
      }
    },

    async personnel_etc_subjectEdit() {
      this.rate_work_course_stds = [];
      this.rate_work_course_stdSubs = [];
      let subject = this.editpersonnel_etc.personnel_etc_subject;
      if (subject != "66") {
        let result = await this.$http.post("rate_work_course_std.php", {
          ApiKey: this.ApiKey,
          id_type_course: this.editpersonnel_etc.personnel_etc_subject
        });
        this.rate_work_course_stds = result.data;
      } else {
        this.short_courseQueryAdd();
      }
    },

    async rate_work_course_stdsQueryAdd() {
      let result = await this.$http.post("rate_work_course_std.php", {
        ApiKey: this.ApiKey,
        id_type_course: this.addpersonnel_etc.personnel_etc_subject
      });
      this.rate_work_course_stds = result.data;
    },

    async rate_work_course_stdsQueryAddSearch() {
      let result = await this.$http.post("rate_work_course_std.php", {
        ApiKey: this.ApiKey,
        id_type_course: this.addpersonnel_etc.personnel_etc_subject
      });
      this.rate_work_course_stds = result.data;
    },

    async short_courseQueryAdd() {
      let result = await this.$http.post("short_course.php", {
        ApiKey: this.ApiKey
      });
      this.rate_work_course_stds = result.data;
    },

    async rate_work_course_stdsQueryAll() {
      let result = await this.$http.post("rate_work_course_std.php", {
        ApiKey: this.ApiKey
      });
      this.rate_work_course_stds = result.data;
    },

    async rate_work_course_stdSubBrach() {
      let subject = this.addpersonnel_etc.personnel_etc_subject;
      if (subject != "66") {
        this.rate_work_course_stdSubs = [];
        let result = await this.$http.post("rate_work_course_std.php", {
          ApiKey: this.ApiKey,
          id_course_branch: this.addpersonnel_etc.personnel_etc_branch,
          searchGroupID: "Ok"
        });
        this.rate_work_course_stdSubs = result.data;
      } else {
        this.rate_work_course_stdSubs = [];
        let result = await this.$http.post("short_course.php", {
          ApiKey: this.ApiKey,
          short_courseSTBranchID: this.addpersonnel_etc.personnel_etc_branch
        });
        this.rate_work_course_stdSubs = result.data;
      }
    },
    async personnel_etc_branchEdit() {
      let subject = this.editpersonnel_etc.personnel_etc_subject;

      if (subject != "66") {
        this.rate_work_course_stdSubs = [];
        let result = await this.$http.post("rate_work_course_std.php", {
          ApiKey: this.ApiKey,
          id_type_course: this.editpersonnel_etc.personnel_etc_subject
        });
        this.rate_work_course_stdSubs = result.data;
      } else {
        this.rate_work_course_stdSubs = [];
        let result = await this.$http.post("short_course.php", {
          ApiKey: this.ApiKey,
          short_courseSTBranchID: this.editpersonnel_etc.personnel_etc_branch
        });
        this.rate_work_course_stdSubs = result.data;
      }
    },

    async rate_work_course_stdSubBrachEdit() {
      let subject = this.addpersonnel_etc.personnel_etc_subject;
      if (subject != "66") {
        this.rate_work_course_stdSubs = [];
        let result = await this.$http.post("rate_work_course_std.php", {
          ApiKey: this.ApiKey,
          id_type_course: this.addpersonnel_etc.personnel_etc_subject
        });
        this.rate_work_course_stdSubs = result.data;
      } else {
        this.rate_work_course_stdSubs = [];
        let result = await this.$http.post("short_course.php", {
          ApiKey: this.ApiKey,
          short_courseSTBranchID: this.addpersonnel_etc.personnel_etc_branch
        });
        this.rate_work_course_stdSubs = result.data;
      }
    },

    async personnel_etcsQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("personnel_etc.php", {
          ApiKey: this.ApiKey,
          personnel_etc_college_code: userSession.user_name
        })
        .finally(() => (this.loading = false));
      this.personnel_etcs = result.data;
    },

    async personnel_etcSearchSYS() {
      let result = await this.$http.post("personnel_etc.php", {
        ApiKey: this.ApiKey,
        personnel_etc_id_card: this.searchIDcard
      });
      this.personnel_etcSYSs = result.data;
      if (result.data.personnel_etc_id_card) {
      } else {
        Swal.fire({
          icon: "warning",
          title: "ไม่พบข้อมูลบุคคลดังกล่าว",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },

    async personnel_etcsCount() {
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http.post("personnel_etc.php", {
        ApiKey: this.ApiKey,
        count_personnel: "Ok",
        personnel_etc_college_code: userSession.user_name
      });
      this.personnel_etcsCounts = result.data;
      let data = this.personnel_etcsCounts;
      let sum = 0;
      for (var el in data) {
        if (data.hasOwnProperty(el)) {
          sum += parseFloat(data[el]);
        }
      }
      this.personnel_etcsCounts.sumAll = sum;
    },

    async searchPersonnel_etcdialogSubmit() {
      if (this.$refs.searchPersonnel_etcdialogform.validate()) {
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
        this.searchadd_temporary.ApiKey = this.ApiKey;
        this.searchadd_temporary.personnel_etc_college_code =
          userSession.user_name;
        this.searchadd_temporary.personnel_etc_m_type = this.personnel_temporarys.user_status;
        this.searchadd_temporary.personnel_etc_id_card = this.personnel_temporarys.id_card;
        this.searchadd_temporary.personnel_etc_title = this.personnel_temporarys.title_s;
        this.searchadd_temporary.personnel_etc_fristname = this.personnel_temporarys.frist_name;
        this.searchadd_temporary.personnel_etc_lastname = this.personnel_temporarys.last_name;
        this.searchadd_temporary.personnel_etc_id_position = this.personnel_temporarys.id_position;
        this.searchadd_temporary.personnel_etc_level = this.personnel_temporarys.rang_level;
        this.searchadd_temporary.personnel_etc_rang = this.personnel_temporarys.rang_name;
        this.searchadd_temporary.personnel_etc_salary = this.personnel_temporarys.salary_s;
        this.searchadd_temporary.personnel_etc_subject = this.addpersonnel_etc.personnel_etc_subject;
        this.searchadd_temporary.personnel_etc_branch = this.addpersonnel_etc.personnel_etc_branch;
        this.searchadd_temporary.personnel_etc_branch_sub = this.addpersonnel_etc.personnel_etc_branch_sub;

        let result = await this.$http.post(
          "personnel_etc.insert.php",
          this.searchadd_temporary
        );

        if (result.data.status == true) {
          this.personnel_etc = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnel_etcsQueryAll();
          this.personnel_etcsCount();
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "insert search";
          this.data_syslog.page_log = "personnel_etc";
          this.data_syslog.table_log = "personnel_etc.insert";
          this.data_syslog.detail_log = this.searchadd_temporary.personnel_etc_id_card;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.addpersonnel_etcdialog = false;
        this.searchPersonnel_etcdialog = false;
      }
    },

    //Add data
    async personnel_etcAdd() {
      this.addpersonnel_etc = {};
      this.addpersonnel_etcdialog = true;
      this.workgroupQuery();
    },

    async addpersonnel_etcSubmit() {
      if (this.$refs.addpersonnel_etcform.validate()) {
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
        this.addpersonnel_etc.ApiKey = this.ApiKey;
        this.addpersonnel_etc.personnel_etc_college_code =
          userSession.user_name;

        let result = await this.$http.post(
          "personnel_etc.insert.php",
          this.addpersonnel_etc
        );
 
        if (result.data.status == true) {
          this.personnel_etc = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnel_etcsQueryAll();
          this.personnel_etcsCount();
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "insert";
          this.data_syslog.page_log = "personnel_etc";
          this.data_syslog.table_log = "personnel_etc.insert";
          this.data_syslog.detail_log = this.addpersonnel_etc.personnel_etc_id_card;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          Swal.fire({
            icon: "warning",
            title: "ข้อมูลซ้ำในระบบหรือกรอกข้อมูลไม่ครบ ให้ดำเนินการให้ถูกต้องก่อนกดบันทึก",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.addpersonnel_etcdialog = false;
      }
    },

    //Edit data
    async personnel_etcEdit(personnel_etc_id_card) {
      let result = await this.$http.post("personnel_etc.php", {
        ApiKey: this.ApiKey,
        personnel_etc_id_card: personnel_etc_id_card
      });

      this.editpersonnel_etc = result.data;
      
      this.personnel_etc_branchEdit();
      this.editpersonnel_etcdialog = true;
    },

    async editpersonnel_etcSubmit() {
      if (this.$refs.editpersonnel_etcform.validate()) {
        this.editpersonnel_etc.ApiKey = this.ApiKey;

        let result = await this.$http.post(
          "personnel_etc.update.php",
          this.editpersonnel_etc
        );
        if (result.data.status == true) {
          this.personnel_etc = result.data;
          Swal.fire({
            icon: "success",
            title: "แก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnel_etcsQueryAll();
          this.personnel_etcsCount();
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "update";
          this.data_syslog.page_log = "personnel_etc";
          this.data_syslog.table_log = "personnel_etc.update";
          this.data_syslog.detail_log = this.editpersonnel_etc.personnel_etc_id_card;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          Swal.fire({
            icon: "warning",
            title: "แก้ไขข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editpersonnel_etcdialog = false;
      }
    },

    //DELETE data
    async personnel_etcDelete(personnel_etc_id_card) {
      let result = await this.$http.post("personnel_etc.php", {
        ApiKey: this.ApiKey,
        personnel_etc_id_card: personnel_etc_id_card
      });
      this.editpersonnel_etc = result.data;
      this.deletepersonnel_etcdialog = true;
    },

    async deletepersonnel_etcubmit() {
      if (this.$refs.deletepersonnel_etcform.validate())
        this.editpersonnel_etc.ApiKey = this.ApiKey;
      {
        let result = await this.$http.post(
          "personnel_etc.delete.php",
          this.editpersonnel_etc
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ลบข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnel_etcsQueryAll();
          this.personnel_etcsCount();
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "delete";
          this.data_syslog.page_log = "personnel_etc";
          this.data_syslog.table_log = "personnel_etc.delete";
          this.data_syslog.detail_log = this.editpersonnel_etc.personnel_etc_id_card;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          Swal.fire({
            icon: "warning",
            title: "ลบข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.deletepersonnel_etcdialog = false;
      }
    },

    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },
    row_classes(item) {
      if (!item.personnel_etc_m_type || item.personnel_etc_m_type == "") {
        return "yellow";
      } else if (
        !item.personnel_etc_subject &&
        item.personnel_etc_m_type == "teacher"
      ) {
        return "amber";
      } else if (
        !item.personnel_etc_subject &&
        item.personnel_etc_m_type == "temporary"
      ) {
        return "amber";
      } else if (
        !item.personnel_etc_subject &&
        item.personnel_etc_m_type == "governmentteach"
      ) {
        return "amber";
      }
    }
  },

  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    },
    color() {
      return "brown-darken-4";
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>
<style>
.v-data-table thead th {
  font-size: 16px !important;
}
</style>
